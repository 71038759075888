<template>
  <b-card class="p-1">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <b-form-group class="w-50 mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Wyszukaj zgłoszenie" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Wyczyść </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>
    <b-table
      responsive
      bordered
      striped
      hover
      :items="chatRecords"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn">
      <template #cell(message)="row">
        <span>{{ row.item.message.length > 40 ? row.item.message.substring(0, 40) + '...' : row.item.message }}</span>
      </template>
      <template #cell(actions)="row">
        <div class="d-flex flex-column align-items-center">
          <b-button
            :to="{ name: 'chat-show', params: { id: row.item.id } }"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="btn table-btn"
            size="sm">
            Zobacz
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import NotificationMixin from '@/mixins/NotificationMixin';
import { all } from '@/http/chat';
export default {
  mixins: [NotificationMixin],
  data() {
    return {
      chatRecords: [],
      filter: '',
      filterOn: [],
      fields: [
        { key: 'name', label: 'Zgłaszający', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'message', label: 'Wiadomość', sortable: true },
        { key: 'created_at', label: 'Data', sortable: true },
        { key: 'actions', label: 'Akcje', class: 'w-25' },
      ],
    };
  },
  async mounted() {
    this.fetchChatRecords();
  },
  methods: {
    async fetchChatRecords() {
      try {
        const { data } = await all();
        this.chatRecords = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił problem z pobraniem zgłoszeń klientów. Skontaktuj się ze swoim developerem.',
        );
      }
    },
  },
};
</script>
<style lang="scss"></style>
